import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { CourseItem } from './da'
import ConfigAPI from '../../../../config/configApi'
import { CourseController } from './controller'

interface CourseSimpleResponse {
    data?: CourseItem,
    onLoading?: boolean,
    type?: string
}

const initState: CourseSimpleResponse = {
    data: undefined,
    onLoading: false
}

export const courseSlice = createSlice({
    name: 'course',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            console.log(action.payload.type)
            switch (action.payload.type) {
                case 'GETBYID':
                    state.data = action.payload.data
                    break;
                case 'UPDATE':
                    state.data = action.payload.data
                    break;
                // case 'LOGOUT':
                //     state.data = undefined
                //     state.onLoading = false
                //     state.type = undefined
                //     break;
                // case 'CHANGEPASS':
                //     state.data = action.payload.data
                //     break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
        onResetCourse: (state) => {
            state.data = undefined
            state.onLoading = false
            state.type = undefined
        }
    },
})

const { handleActions, onFetching, onResetCourse } = courseSlice.actions

export default courseSlice.reducer
export { onResetCourse }

export class CourseActions {
    static getInfor = (dispatch: Dispatch<UnknownAction>) => {
        dispatch(onFetching())
        CourseController.getById(ConfigAPI.courseId).then(res => {
            if (res) {
                dispatch(handleActions({
                    type: 'GETBYID',
                    data: res,
                }))
            }
        })
    }

    static update = (dispatch: Dispatch<UnknownAction>, courseItem: CourseItem) => {
        dispatch(onFetching())
        CourseController.edit(courseItem).then(res => {
            if (res) {
                dispatch(handleActions({
                    type: 'UPDATE',
                    data: courseItem,
                }))
            }
        })
    }

    // static logout = async (dispatch: Dispatch<UnknownAction>) => {
    //     dispatch(onFetching())
    //     AccountController.logout()
    //     dispatch(handleActions({
    //         type: 'LOGOUT',
    //     }))
    // }
}