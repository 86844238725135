import { FilledSEdit } from "../../../../assets/const/icon"
import { Text, TextField } from "wini-web-components"
import { AccountController } from "../../account/controller"
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { useEffect, useState } from "react"
import './home.css'
import ListNews from "./local-component/list-news"
import SidebarActions from "../../../layout/sidebar/sidebar-actions"
import { useSelector } from "react-redux"
import RightSidebar from "./local-component/right-sidebar"
import { showLoginPopup } from "../../../layout/main-layout"
import { ViewPath } from "../../../../router/router"

export default function SocialHome() {
    const { id } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const isLogin = AccountController.token()
    const userInfor = useSelector((state) => state.account.data)
    const [selectedTab, setSelectedTab] = useState()

    useEffect(() => {
        if (id && userInfor?.id === id) {
            setSelectedTab(0)
        } else {
            setSelectedTab(1)
        }
    }, [location.pathname, userInfor])

    return <>
        <div className="body-sidebar left col" style={{ gap: '2.4rem' }}>
            <TextField
                prefix={<FontAwesomeIcon icon={faSearch} />}
                style={{ height: '4rem', padding: '0.8rem 1.6rem', margin: '0.4rem 0' }}
                className="search-default body-3"
                placeholder="Tìm kiếm bài viết"
            />
            <div className="row">
                <Text maxLine={1} style={{ flex: 1, width: '100%' }} className="heading-6">Bảng tin</Text>
                <button type="button" onClick={() => {
                    if (isLogin) {
                        navigate(ViewPath.NewsForm)
                    } else {
                        showLoginPopup()
                    }
                }} className="row button-infor" style={{ backgroundColor: 'transparent' }}>
                    <FilledSEdit width="1.4rem" height="1.4rem" color="#bc2031" />
                    <div className="button-text-5" style={{ '--infor-color': '#bc2031' }}>Tạo bài viết</div>
                </button>
            </div>
            <div className="col" style={{ flex: 1, height: '100%', overflow: 'hidden auto', gap: '1.2rem' }}>
                {userInfor?.id ? <NavLink to={`${ViewPath.MyHomePage}/${userInfor.id}`} className={`news-bookmark-tab ${selectedTab === 0 ? 'selected' : ''}`}>
                    <div className="label-3">Trang cá nhân</div>
                </NavLink> : null}
                <NavLink to={'/'} className={`news-bookmark-tab ${selectedTab === 1 ? 'selected' : ''}`}>
                    <div className="label-3">Bảng tin</div>
                </NavLink>
                {/* <NavLink to={'/social/my-news'} className={`news-bookmark-tab ${selectedTab === 2 ? 'selected' : ''}`}>
                    <div className="label-3">Đã lưu</div>
                </NavLink> */}
            </div>
            <SidebarActions />
        </div>
        <ListNews />
        <div className="body-sidebar right col" style={{ overflow: 'hidden auto', scrollbarWidth: 'none' }}><RightSidebar /></div>
    </>
}