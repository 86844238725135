import { useDispatch, useSelector } from 'react-redux';
import { Popup, Text, ToastMessage } from 'wini-web-components';
import './home.css'
import GroupDefaultBg from '../../../assets/groups-bg.png'
import { useEffect, useRef, useState } from 'react';
import { TopicController } from '../topic/controller';
import { CenterController } from './controller';
import { CenterPermisson } from './da';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { CustomerController } from '../customer/controller';
import CommonTab from './local-component/common';
import ListMember from './local-component/list-member';
import ConfigAPI from '../../../config/configApi';
import { uploadFiles } from '../../baseDA';
import CenterEduTab from './local-component/education';
import AdditionalClass from '../edu/course/local-component/additional-class';
import { CenterActions } from './reducer';

export default function CenterView() {
    const centerData = useSelector((state) => state.center.data)
    const courseData = useSelector((state) => state.course.data)
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const centerId = searchParams.get("id")
    const userInfor = useSelector((state) => state.account.data)
    const permisson = userInfor?.customerCenters?.find(e => e.centerId === centerId)?.permisson
    const [activeTab, setActiveTab] = useState(0)
    const [fixedTabbar, setFixedTabbar] = useState()
    const [members, setMembers] = useState({ totalCount: undefined, data: [] })
    const ref = useRef()
    const filePickerRef = useRef()
    const dispatch = useDispatch()

    const getMembers = async () => {
        const res = await CenterController.getListSimpleMember({ page: 1, take: 8, filter: [{ field: 'centerId', operator: '=', value: centerId }] })
        if (res) {
            const customerIds = res.data.map(e => e.customerId)
            if (customerIds.length) {
                const customerItems = await CustomerController.getByIds(customerIds)
                if (!customerItems) return
                setMembers({
                    totalCount: res.totalCount,
                    data: customerItems
                })
            }
        }
    }

    const renderUI = () => {
        switch (activeTab) {
            case 1: // Thành viên
                return <ListMember centerItem={centerData} permisson={permisson} reloadMember={members} onDelete={getMembers} />;
            case 2: // Đào tạo
                // return <CenterEduTab centerItem={centerData} permisson={permisson} />;
                return <>
                    {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <CenterEduTab centerItem={centerData} permisson={permisson} /> : null}
                    <AdditionalClass courseData={courseData} />
                </>
            case 3: // Sản phẩm
                return <div></div>;
            case 4: // Doanh thu
                return <div></div>;
            default: // 0: Bài viết
                return <CommonTab centerItem={centerData} userInfor={userInfor} permisson={permisson} />;
        }
    }

    const pickThumbnail = async (ev) => {
        if (ev.target.files && ev.target.files[0]) {
            const res = await uploadFiles([ev.target.files[0]])
            if (res) {
                CenterActions.update(dispatch, {
                    ...centerData,
                    thumbnailId: res[0].id
                })
            }
        }
    }

    useEffect(() => {
        getMembers()
    }, [userInfor, centerData])

    useEffect(() => {
        const handleScroll = () => {
            let _tabbar = document.getElementById('handle-tabbar')
            if (!_tabbar) {
                document.body.querySelector('.main-layout').removeEventListener('scroll', handleScroll)
                return
            }
            _tabbar = _tabbar.getBoundingClientRect()
            const _header = document.body.querySelector('.header').getBoundingClientRect()
            if (_tabbar.y < _header.height) {
                setFixedTabbar({ position: 'fixed', top: _header.height, width: _tabbar.width, zIndex: 2 })
            } else if (_tabbar.y >= _header.height) {
                setFixedTabbar(undefined)
            }
        }
        document.body.querySelector('.main-layout').addEventListener('scroll', handleScroll)
    }, [])

    return <div className='col main-layout-body'>
        <Popup ref={ref} />
        <div className='center-picture' style={{ position: 'relative', '--img-url': `url(${centerData?.thumbnailId ? (ConfigAPI.imgUrl + centerData.thumbnailId) : GroupDefaultBg})` }}>
            {/* <img src={} alt='' style={{  }} /> */}
            {permisson === CenterPermisson.owner || permisson === CenterPermisson.admin ? <button type='button' className='row edit-button' onClick={() => { filePickerRef.current.click() }}>
                <input accept={'image/jpg, image/png, image/jpeg'} ref={filePickerRef} type='file' hidden onChange={pickThumbnail} />
                <FontAwesomeIcon icon={faEdit} style={{ fontSize: '2rem', color: '#fff' }} />
                <Text className='button-text-2' style={{ color: '#fff' }}>Chỉnh sửa</Text>
            </button> : undefined}
        </div>
        <div id='handle-tabbar' style={{ height: '4rem', marginTop: '2.4rem', padding: '0 2.4rem' }}>
            <div className='row' style={{ justifyContent: 'center', backgroundColor: '#fff', ...(fixedTabbar ?? {}) }}>
                <div className='col col18-xxl col16-xl col12-lg col24' style={{ '--gutter': '0px' }}>
                    <div className="tab-header-2 row" style={{ overflow: 'auto hidden', scrollbarWidth: 'none', gap: '0.8rem' }}>
                        <div className={`tab-btn label-4 row ${activeTab === 0 ? 'selected' : ''}`} style={{ width: '8.8rem', justifyContent: 'center' }} onClick={() => { setActiveTab(0) }}>Giới thiệu</div>
                        <div className={`tab-btn label-4 row ${activeTab === 1 ? 'selected' : ''}`} style={{ width: '8.8rem', justifyContent: 'center' }} onClick={() => { setActiveTab(1) }}>Thành viên</div>
                        <div className={`tab-btn label-4 row ${activeTab === 2 ? 'selected' : ''}`} style={{ width: '8.8rem', justifyContent: 'center' }} onClick={() => { setActiveTab(2) }}>Lớp học</div>
                        {/* <div className={`tab-btn label-4 row ${activeTab === 3 ? 'selected' : ''}`} style={{ width: '8.8rem', justifyContent: 'center' }} onClick={() => { setActiveTab(3) }}>Chứng chỉ</div>
                        <div className={`tab-btn label-4 row ${activeTab === 4 ? 'selected' : ''}`} style={{ width: '8.8rem', justifyContent: 'center' }} onClick={() => { setActiveTab(4) }}>Sản phẩm</div> */}
                    </div>
                </div>
            </div>
        </div>
        <div className='row' style={{ justifyContent: 'center', padding: '0 2.4rem' }}>
            <div className='col col18-xxl col16-xl col12-lg col24' style={{ '--gutter': '0px' }}>
                {renderUI()}
            </div>
        </div>
    </div>

}
