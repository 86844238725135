import { Dispatch, UnknownAction, configureStore } from '@reduxjs/toolkit'
import accountReducer from './screen/module/account/reducer'
import centerReducer from './screen/module/center/reducer'
import courseReducer from './screen/module/edu/course/reducer'

export const store = configureStore({
    reducer: {
        account: accountReducer,
        center: centerReducer,
        course: courseReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch