import { Dispatch, PayloadAction, UnknownAction, createSlice } from '@reduxjs/toolkit'
import { CenterItem } from './da'
import { CenterController } from './controller'
import ConfigAPI from '../../../config/configApi'
import { TopicController } from '../topic/controller'

interface CenterSimpleResponse {
    data?: CenterItem,
    onLoading?: boolean,
    type?: string
}

const initState: CenterSimpleResponse = {
    data: undefined,
    onLoading: false
}

export const centerSlice = createSlice({
    name: 'center',
    initialState: initState,
    reducers: {
        handleActions: (state, action: PayloadAction<any>) => {
            console.log(action.payload.type)
            switch (action.payload.type) {
                case 'GETBYID':
                    state.data = action.payload.data
                    break;
                case 'UPDATE':
                    state.data = action.payload.data
                    break;
                // case 'LOGOUT':
                //     state.data = undefined
                //     state.onLoading = false
                //     state.type = undefined
                //     break;
                // case 'CHANGEPASS':
                //     state.data = action.payload.data
                //     break;
                default:
                    break;
            }
            state.onLoading = false
        },
        onFetching: (state) => {
            state.onLoading = true
        },
        onResetCenter: (state) => {
            state.data = undefined
            state.onLoading = false
            state.type = undefined
        }
    },
})

const { handleActions, onFetching, onResetCenter } = centerSlice.actions

export default centerSlice.reducer
export { onResetCenter }

export class CenterActions {
    static getInfor = (dispatch: Dispatch<UnknownAction>) => {
        dispatch(onFetching())
        CenterController.getById(ConfigAPI.centerId).then(async (res) => {
            if (res) {
                const topicRes = await TopicController.getById(res.topicId ?? "")
                dispatch(handleActions({
                    type: 'GETBYID',
                    data: {
                        ...res,
                        topicName: topicRes?.name
                    },
                }))
            }
        })
    }

    static update = (dispatch: Dispatch<UnknownAction>, centerItem: CenterItem) => {
        dispatch(onFetching())
        CenterController.edit(centerItem).then(res => {
            if (res) {
                dispatch(handleActions({
                    type: 'UPDATE',
                    data: centerItem,
                }))
            }
        })
    }

    // static logout = async (dispatch: Dispatch<UnknownAction>) => {
    //     dispatch(onFetching())
    //     AccountController.logout()
    //     dispatch(handleActions({
    //         type: 'LOGOUT',
    //     }))
    // }
}