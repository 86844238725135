import { useEffect, useState } from "react";
import { Checkbox, CustomSlider, InfiniteScroll, ProgressBar, Rating, Text, TextField } from "wini-web-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faRotate, faSearch } from "@fortawesome/free-solid-svg-icons";
import { CourseController } from "../../course/controller";
import { PostCard } from "../../../../../project-component/card";
import { CustomerController } from "../../../customer/controller";
import { OutlineHeart, OutlineShoppingCart, OutlineStar, OutlineUserProfile } from "../../../../../assets/const/icon";
import { Ultis } from "../../../../../Utils";
import ConfigAPI from "../../../../../config/configApi";
import { RatingController } from "../../rating/controller";
import { CourseStatus } from "../../course/da";
import { TopicController } from "../../../topic/controller";
import { OrderController } from "../../../ecom/order/controller";
import { OrderType } from "../../../ecom/order/da";
import SidebarActions from "../../../../layout/sidebar/sidebar-actions";
import { NavLink } from "react-router-dom";
import { ViewPath } from "../../../../../router/router";

export default function EduListCourses() {
    const [topicList, setTopicList] = useState([])
    const [openAll, setOpenAll] = useState(false)
    const [myCourses, setMyCourses] = useState([])
    const searchParams = new URLSearchParams(window.location.search);
    const [selectedTopics, setSelectedTopics] = useState([])

    useEffect(() => {
        let listFilter = []
        for (const [key, value] of searchParams) {
            if (key === 'topicId')
                listFilter.push(value)
        }
        setSelectedTopics(listFilter)
        TopicController.getAll().then(res => {
            if (res) setTopicList(res)
        })
        OrderController.getListSimple({
            filter: [
                { field: 'type', operator: '=', value: OrderType.course },
                { field: 'statusPayment', operator: '=', value: 2 }
            ]
        }).then(async (res) => {
            if (res?.data?.length) {
                const courseIds = res.data.map(e => e.orderDetails).reduce((a, b) => a.concat(b)).filter(e => e.type === OrderType.course).map(e => e.productId)
                const myCoursesRes = await CourseController.getLearningProgressByIds(courseIds)
                if (myCoursesRes) {
                    setMyCourses(myCoursesRes)
                }
            }
        })
    }, [window.location.search])

    return <>
        <div className='col body-sidebar left' >
            <TextField
                className='search-default placeholder-2'
                placeholder='Tìm kiếm khóa học'
                prefix={<FontAwesomeIcon icon={faSearch} />}
            />
            <div className='col' style={{ flex: 1, gap: '2.4rem' }}>
                <div className='row' style={{ padding: '0 1.6rem' }}>
                    <Text className='heading-6'>Khoá học theo chủ đề</Text>
                </div>
                <div className='col' style={{ flex: 1, height: '100%', overflow: 'hidden auto' }}>
                    {topicList.slice(0, openAll ? topicList.length : 6).map((item, i) => {
                        return <div key={'filter-topic-' + i} className='row' style={{ gap: '0.8rem', padding: '1rem 1.6rem' }}>
                            <Checkbox size='2rem' value={selectedTopics.some(id => id === item.id)} />
                            <Text style={{ flex: 1, width: '100%' }} className='label-4' maxLine={1}>{item.name}</Text>
                        </div>
                    })}
                    <button type='button' className='row button-infor' style={{ backgroundColor: 'transparent', padding: '1rem 1.6rem' }} onClick={() => setOpenAll(!openAll)}>
                        <Text className='button-text-3'>Xem thêm</Text>
                        <FontAwesomeIcon icon={openAll ? faChevronUp : faChevronDown} style={{ fontSize: '1.4rem' }} />
                    </button>
                </div>
                <SidebarActions />
            </div>
        </div>
        <div className='row main-layout-body' style={{ padding: '2.4rem 0', justifyContent: 'center', alignItems: 'start' }}>
            <div className='col col24-md col24-sm col24-min' style={{ gap: '4.8rem', width: '90%', padding: '0 1.6rem', minHeight: myCourses.length ? 'calc(100% - 40rem)' : null }}>
                {myCourses.length ? <CustomSlider style={{ height: '30.8rem', borderRadius: '0.8rem', overflow: 'hidden' }}>
                    {myCourses.map(e => {
                        const myProgress = e.countLessonUsed ? Math.round((e.countLessonUsed / e.countLesson) * 100) : 0
                        return <div key={e.id} style={{ backgroundImage: `url(${ConfigAPI.imgUrl + e.pictureId})`, backgroundSize: 'cover' }}>
                            <div className='col' style={{ width: '100%', height: '100%', justifyContent: 'center', padding: '1.6rem 8rem', gap: '2.4rem' }}>
                                <div className='heading-6' style={{ color: '#ffffff' }}>Khóa học của tôi</div>
                                <div className='col' style={{ gap: '1.6rem' }}>
                                    <Text className='heading-4' maxLine={2} style={{ color: '#ffffff' }}>{e.name}</Text>
                                    {/* </div> */}
                                    <div className='col'>
                                        <ProgressBar percentColor='#ffffff' fullColor='#00000000' progressBarOnly percent={myProgress} progressBarStyle={{ border: '1px solid #ffffff' }} />
                                        <div className='label-5' style={{ color: "#ffffff" }}>{myProgress ? `Bạn đã hoàn thành ${myProgress}% khóa học này` : 'Bạn chưa bắt đầu học khóa học này'}</div>
                                    </div>
                                </div>
                                <div className='row' style={{ gap: '1.2rem' }}>
                                </div>
                                <NavLink to={ViewPath.CourseView + `?id=${e.id}`} className='button-primary row' style={{ backgroundColor: '#00000000', border: '1px solid #ffffff' }}>
                                    <div className='button-text-3'>{myProgress ? 'Học tiếp' : 'Bắt đầu học'}</div>
                                </NavLink>
                            </div>
                        </div>
                    })}
                </CustomSlider> : null}
                <ListAllCourse />
            </div>
        </div >
    </>
}

function ListAllCourse() {
    const [data, setData] = useState([])
    const [customerList, setCustomerList] = useState([])
    const [total, setTotal] = useState(0)
    const searchParams = new URLSearchParams(window.location.search);


    const getData = async () => {
        let listFilter = [{ field: 'status', operator: '=', value: CourseStatus.published }]
        for (const [key, value] of searchParams) {
            listFilter.push({ field: key, operator: '=', value: value })
        }
        const res = await CourseController.getListSimple({ page: Math.floor((data.length / 30)) + 1, take: 30, filter: listFilter })
        if (res) {
            if (total !== res.totalCount) setTotal(res.totalCount)
            let customerIds = res.data.map(e => e.customerId).filter(id => customerList.every(item => item.id !== id))
            if (customerIds.length) {
                CustomerController.getByIds(customerIds).then(cusRes => {
                    if (cusRes) setCustomerList(cusRes)
                })
            }
            const ratingRes = await RatingController.getByLinkIds(res.data.map(e => e.id))
            setData([...data, ...res.data.map(e => {
                const ratingData = ratingRes.find(rate => rate.linkId === e.id)
                return {
                    ...e,
                    totalStar: ratingData?.totalStart,
                    totalComment: ratingData?.totalComment,
                }
            })])
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return <div className="col" style={{ gap: '2rem', flex: 1 }}>
        <Text className="heading-5">Khám phá tất cả khoá học</Text>
        <div className="row" style={{ gap: '3.2rem', padding: '1.2rem 0', width: '100%', borderTop: 'var(--border-grey1)', borderBottom: 'var(--border-grey1)' }}>
            <div className="row" style={{ flex: 1, overflow: 'auto hidden', scrollbarWidth: 'none' }}>
                <button className="col" style={{ gap: '0.4rem' }}>
                    <div className="row" style={{ gap: '0.8rem' }}>
                        <div className="body-3">Khoảng giá</div>
                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '1.4rem', color: '#00204D99' }} />
                    </div>
                    <div className="heading-8">Tất cả</div>
                </button>
                <div className="row divider" style={{ height: '4.8rem' }}></div>
                <button className="col" style={{ gap: '0.4rem' }}>
                    <div className="row" style={{ gap: '0.8rem' }}>
                        <div className="body-3">Trạng thái khoá học</div>
                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '1.4rem', color: '#00204D99' }} />
                    </div>
                    <div className="heading-8">Tất cả</div>
                </button>
                <div className="row divider" style={{ height: '4.8rem' }}></div>
                <button className="col" style={{ gap: '0.4rem' }}>
                    <div className="row" style={{ gap: '0.8rem' }}>
                        <div className="body-3">Đánh giá</div>
                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '1.4rem', color: '#00204D99' }} />
                    </div>
                    <div className="heading-8"><Rating value={5} /></div>
                </button>
                <div className="row divider" style={{ height: '4.8rem' }}></div>
                <button className="col" style={{ gap: '0.4rem' }}>
                    <div className="row" style={{ gap: '0.8rem' }}>
                        <div className="body-3">Thời gian đăng</div>
                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '1.4rem', color: '#00204D99' }} />
                    </div>
                    <div className="heading-8">Tháng trước</div>
                </button>
                <div className="row divider" style={{ height: '4.8rem' }}></div>
                <button className="col" style={{ gap: '0.4rem' }}>
                    <div className="row" style={{ gap: '0.8rem' }}>
                        <div className="body-3">Khuyến mại</div>
                        <FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '1.4rem', color: '#00204D99' }} />
                    </div>
                    <div className="heading-8">Đang khuyến mại</div>
                </button>
            </div>
            <button className="row button-grey" style={{ borderRadius: 0, borderLeft: 'var(--border-grey1)', backgroundColor: 'transparent', padding: '1.2rem 3.2rem' }}>
                <Text className="button-text-3">Đặt lại</Text>
                <FontAwesomeIcon icon={faRotate} style={{ fontSize: '1.4rem' }} />
            </button>
        </div>
        <div className="row" style={{ flexWrap: 'wrap', gap: '3.2rem 4rem', alignItems: 'stretch' }}>
            {data.map((item, i) => {
                const customer = customerList.find(e => e.id === item.customerId)
                return <PostCard
                    key={item.id}
                    to={ViewPath.EduCourseView + `?id=${item.id}`}
                    style={{ '--gutter': '4rem', height: '100%', maxHeight: '40rem' }}
                    className="col col6-xxl col6-xl col8-lg col8-md col12"
                    imgStyle={{ height: '12rem' }}
                    imgUrl={ConfigAPI.imgUrl + item.thumbnailId}
                    heading={<div className="row" style={{ gap: '0.8rem' }}>
                        <img src={customer?.avatarUrl} alt="" style={{ width: '2.4rem', height: '2.4rem', borderRadius: '50%' }} />
                        <Text className="label-4">{customer?.name ?? '-'}</Text>
                    </div>}
                    title={item.name}
                    content={item.description}
                    actions={<div className='col' style={{ gap: '2.4rem', flex: 1, justifyContent: 'end', height: '100%' }}>
                        <div className='row' style={{ gap: '0.8rem' }}>
                            <OutlineUserProfile />
                            <Text className='button-text-3'>1k2</Text>
                            <Text className='button-text-3'>-</Text>
                            <OutlineStar />
                            <Text className='button-text-3'>{`${item.totalStar && item.totalComment ? `${(item.totalStar / item.totalComment).toFixed(1)} (${item.totalComment})` : '0 (0)'}`}</Text>
                        </div>
                        <div className='row'>
                            <Text className='heading-7' style={{ flex: 1, width: '96%' }}>{item.price ? Ultis.money(item.price) : ''}</Text>
                            <button type='button' className='row icon-button32'><OutlineHeart width='2rem' height='2rem' /></button>
                            <button type='button' className='row icon-button32'><OutlineShoppingCart width='2rem' height='2rem' /></button>
                        </div>
                    </div>}
                />
            })}
        </div>
    </div>
}