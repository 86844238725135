import React, { useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { FilledSendMessage } from '../../../../../assets/const/icon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ComponentStatus, Dialog, DialogAlignment, Text, ToastMessage, showDialog } from 'wini-web-components';
import { CourseController } from '../controller';
import FormEditLesson from '../../lesson/lesson-details';
import CourseCurriculum from './course-curriculum';
import Overview from './settings-overview';
import { CourseStatus } from '../da';
import AdditionalClass from './additional-class';
// import ExamManagment from '../../exam/exam';
// import EduStudent from '../../student/student';
import { ViewPath } from '../../../../../router/router';

export default function SettingsCourse() {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const id = searchParams.get("id")
    const ref = useRef()
    const naviagte = useNavigate()
    const { tab } = useParams()
    const [data, setData] = useState()
    const [listView, setListView] = useState([
        {
            name: 'Tổng quan',
            id: 'overview',
        },
        {
            name: 'Giáo trình',
            id: 'lessons',
        },
        // {
        //     name: 'Danh sách lớp',
        //     id: 'additional-class',
        // },
    ])

    const submitPublishedCourse = () => {
        if (data?.status !== CourseStatus.published) {
            showDialog({
                ref: ref,
                alignment: DialogAlignment.center,
                status: ComponentStatus.WARNING,
                title: 'Bạn chắc chắn muốn xuất bản khóa học này',
                onSubmit: () => {
                    data.status = CourseStatus.published
                    CourseController.edit(data).then(res => {
                        if (res) {
                            ToastMessage.success('Xuất bản khóa học thành công')
                            naviagte(ViewPath.CourseView + `?id=${id}`)
                        }
                    })
                }
            })
        } else {
            naviagte(ViewPath.CourseView + `?id=${id}`)
        }
    }

    useEffect(() => {
        if (id) {
            CourseController.getById(id).then(res => {
                if (res) setData(res)
            })
        }
    }, [id])

    return <div className="col main-layout-body fillH" style={{ padding: '0 3.2rem' }} >
        <Dialog ref={ref} />
        <div className='row' style={{ padding: '1.6rem 0', borderBottom: 'var(--border-grey1)' }} >
            <div className='col' style={{ gap: '0.8rem' }}>
                <div className='row' style={{ gap: '0.8rem' }}>
                    <Text className='button-text-6' style={{ color: '#00204D99' }}>Danh sách Course</Text>
                    <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '1.2rem', color: '#00204D99' }} />
                    <Text className='button-text-6' style={{ color: 'var(--primary-color)' }}>Chỉnh sửa khóa học</Text>
                </div>
                <div className='heading-6'>Thông tin chi tiết khóa học</div>
            </div>
            <div style={{ flex: 1 }} />
            <button type='button' onClick={submitPublishedCourse} className={`button-primary row`} style={{ padding: '0.6rem 1.2rem' }}>
                <FilledSendMessage color={'#fff'} />
                <div className='button-text-3'>{data?.status === CourseStatus.published ? 'Xem khóa học' : 'Xuất bản khóa học'}</div>
            </button>
        </div>
        <div className='row' style={{ flex: 1 }}>
            <div className='col' style={{ maxWidth: '33.4rem', width: '16%',minWidth: '20rem', height: '100%', padding: '3.2rem 1.6rem 0 0', borderRight: 'var(--border-grey1)', gap: '2.4rem' }}>
                <div className='row' style={{ gap: '0.8rem' }}>
                    <Text className='heading-7' style={{ flex: 1 }} maxLine={2}>{data?.name}</Text>
                    <button type='button' onClick={submitPublishedCourse} className={`Xem khóa học row col0-xxl col0-xl col0-lg col0-md col3-sm col6-min`} style={{ '--gutter': '0.8rem', padding: '0.6rem 1.2rem' }}>
                        <FilledSendMessage color={'#fff'} />
                        <div className='button-text-3'>Xem khóa học</div>
                    </button>
                </div>
                <div className='col' >
                    {listView.map(function (item, i) {
                        const isSelected = tab.includes(item.id)
                        return <NavLink key={'nav-to-' + i} to={`${ViewPath.CourseForm}/${item.id}` + location.search} className={`row`} style={{ width: '100%', padding: '0.9rem 1.6rem', gap: '0.8rem', borderRadius: '0.8rem', backgroundColor: isSelected ? '#00358014' : 'transparent' }}>
                            <Text className='label-3' maxLine={1} style={{ flex: 1, with: '100%' }}>{item.name}</Text>
                        </NavLink>;
                    })}
                </div>
            </div>
            {[
                {
                    slug: 'lessons-settings',
                    element: <FormEditLesson courseData={data} />
                },
                {
                    slug: 'lessons',
                    element: <CourseCurriculum data={data} />
                },
                {
                    slug: 'overview',
                    element: <Overview data={data} />
                },
                // {
                //     slug: 'additional-class',
                //     element: <AdditionalClass courseData={data} />
                // },
            ].find(e => e.slug === tab)?.element}
        </div>
    </div>
}